import classNames from 'classnames';
import { BaseTextFieldProps, InputField } from '../../../../common/form-fields/InputField';
import { useState } from 'react';

export const PatternTextField = ({ maxLength, placeholder, formControlClassName, ...props }: BaseTextFieldProps) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
        <InputField {...props} renderElement={(formik, field, meta) => {
            const withError = !isFocused && meta.touched && meta.error;
            return (
                <div className="data-item-col">
                    <input
                        className={classNames('form-control', formControlClassName, { 'is-invalid': withError })}
                        {...field}
                        maxLength={maxLength}
                        value={field.value || ''}
                        placeholder={placeholder}
                        onFocus={() => {
                            setIsFocused(true);
                        }}
                        onBlur={(e) => {
                            formik.setFieldError(props.name, '')
                            setIsFocused(false);
                            field.onBlur(e);
                        }}
                    />
                    {withError && <div className="form-error">{meta.error}</div>}
                </div>
            )
        }} />
    );
}
