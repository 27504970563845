import { FileUploader } from "../../../../amrPipeline/common/documents/FileUploader";
import { EditDocumentsList } from "./EditDocumentsList";
import { DocumentGroup } from "../../../../amrPipeline/common/documents/DocumentGroup";
import { AmrFile } from "../../../../../types/amr-pipeline/models/AmrFile";
import { DownloadFile } from "../../../../../types/amr-pipeline/models/DownloadedDocument";
import { Nullable } from "../../../../../types/primitives/Nullable";
import { DocumentColumnDate } from "../DocumentsList";
import { DocumentTypeSelect } from "../../../../../types/amr-pipeline/enums/DocumentType";
import { PatternTextField } from "./PatternTextField";

const maxDealDocSize = 50;

interface Props<TArgs extends any[]> {
    title: string;
    dealReferenceName: string;
    items: AmrFile[];
    documentKey: string;
    documentTypes?: DocumentTypeSelect[];
    withPublicColumn?: boolean;
    disabledDate?: boolean;
    downloadAllFetch: (...args: TArgs) => Promise<DownloadFile>;
    downloadAllArgs: TArgs;
    fileNameForSave: Nullable<string>;
    onUpload: (documents: AmrFile[]) => void;
    setUploadProgress: (isUploading: boolean) => void;
    onPatternChange?: (pattern: string) => void;
    patternName?: string;
    documentColumnDate?: DocumentColumnDate;
    renderAdditionalButton?: () => React.ReactNode;
}

export const EditDocumentsSection = <TArgs extends any[]>({
    title,
    dealReferenceName,
    items,
    documentKey,
    documentTypes,
    withPublicColumn,
    disabledDate = false,
    downloadAllFetch,
    downloadAllArgs,
    fileNameForSave,
    onUpload,
    setUploadProgress,
    patternName,
    documentColumnDate = DocumentColumnDate.executionDate,
    renderAdditionalButton,
}: Props<TArgs>) => {
    return (
        <DocumentGroup
            title={title}
            itemsCount={items.filter(i => !i.remove).length}
            downloadAllFetch={downloadAllFetch}
            downloadAllArgs={downloadAllArgs}
            fileNameForSave={fileNameForSave}
            renderAdditionalButton={renderAdditionalButton}
        >
            {patternName && (
                <PatternTextField
                    name={patternName}
                    maxLength={512}
                    formControlClassName="form-control-sm"
                    label={`${title} Mask`}
                    placeholder={`${title} Mask`} 
                />
            )}
            <FileUploader
                referenceName={dealReferenceName}
                documents={items}
                maxFileSizeInMb={maxDealDocSize}
                onChange={onUpload}
                onUploading={setUploadProgress}
            >
                {props => (
                    <EditDocumentsList
                        {...props}
                        documentColumnDate={documentColumnDate}
                        documentKey={documentKey}
                        disabledDate={disabledDate}
                        documentTypes={documentTypes}
                        withPublicColumn={withPublicColumn}
                    />
                )}
            </FileUploader>
        </DocumentGroup>
    );
};

