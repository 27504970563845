import * as Yup from "yup";
import { errorMessages } from "../../../../../constants";

export const getDocValidationSchema = (
    getValidatePattern: (expression?: string) => Promise<boolean>
) => {
    return Yup.object().shape({
        distributionReportsPattern: Yup.string().test(
            "distributionReportsPattern-validation",
            errorMessages.invalidField("Distribution Reports Mask"),
            getValidatePattern
        ),
        monthlyReportsPattern: Yup.string().test(
            "monthlyReportsPattern-validation",
            errorMessages.invalidField("Monthly Reports Mask"),
            getValidatePattern
        ),
    });
};
